* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
img {
  width: 100%;
  display: block;
}
body::-webkit-scrollbar {
  width: 0;
}
ul,
ol,
li {
  list-style: none;
}
.fixed {
  height: 50px;
  position: fixed;
  bottom: 0;
  width: 100vw;
  background: #081008;
  color: #fff;
  left: 0;
  z-index: 1111;
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.fixed .nav-item{
  color: #394f64;
}
header {
  height: 95px;
  background: linear-gradient(84deg, #394f64 0%, #f6f6da 100%);
  padding: 28px 82px;
  position: fixed;
  z-index: 11111111;
  width: 100vw;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.body {
  background: #394f64;
  min-height: 100vh;
  padding-top: 95px;
}
header .logo {
  width: 80px;
  height: 38px;
}
header .img {
  width: 20px;
  height: 20px;
}
.language-toggle {
  position: relative;
  display: inline-block;
  padding: 6px 20px;
  border-radius: 5px;
  border: 1px solid #404040;
  display: flex;
  align-items: center;
  cursor: pointer;
  span {
    margin-left: 26px;
    font-family: Microsoft YaHei;
    font-size: 14px;
  }
}

.language-toggle-button {
  display: flex;
  align-items: center;
  border-radius: 4px;
  color: #fff;
  padding: 8px 12px;
  font-size: 16px;
}

.language-toggle-button:hover {
  background-color: #1a1a1a;
}

.language-toggle-button span {
  margin-left: 8px;
}
.dropdown {
  position: absolute;
  top: 100%;
  left: 0;
  background-color: #1a1a1a;
  border-radius: 4px;
  color: #fff;
  padding: 8px 0;
  min-width: 140px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  z-index: 1000;
}

.dropdown div {
  padding: 8px 12px;
  cursor: pointer;
}

@media screen and (max-width: 600px) {
  .body {
    padding-top: 46px;
  }
  header {
    height: 46px;
    padding: 5px 41px;
  }
  header .logo {
    width: 40px;
    height: 19px;
  }
  header .img {
    width: 20px;
    height: 20px;
  }
}
