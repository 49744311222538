.tabList {
  line-height: 60px;
  background: #081008;
  display: flex;
  color: #fff;
  justify-content: space-around;
}

.tabList > div {
  padding: 0 23px;
  cursor: pointer;
}
.tabList .tab.active {
  background-color:  #394f64;
}
.sniperRobot{
  text-align: justify;
  padding:30px;
  color:#FFF;
}
.sniperRobot p{
  margin-bottom: 30px;
}