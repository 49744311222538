
.text {
  margin: 0 auto;
  background-color: #000;
  padding: 105px 0;
  color: #fff;
}
.text div {
  width: 60%;
  margin: 0 auto;
  font-family: Arial;
  line-height: 28px;
  margin-bottom: 20px;
}
.robot {
  position: relative;
}
.robot img {
  display: block;
  width: 100%;
}
button {
  width: 202px;
  height: 64px;
  background: linear-gradient(-54deg, #f8ac1b 0%, #ffe735 100%);
  border-radius: 8px;
  position: absolute;
  bottom: 30px;
  right: 24%;
  border: none;
  font-family: Arial;
  font-size: 20px;
}
.mining {
  line-height: 100px;
  width: 100%;
  background: linear-gradient(85deg, #469bae 0%, #aadbe0 100%);
  border: 2px solid #87c4ce;
  font-family: Arial;
  font-size: 24px;
}
.center {
  margin: 0 auto;
  width: 300px;
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.center div:nth-child(2) {
  width: 2px;
  height: 40px;
  background-color: #000;
}
.center img {
  width: 36px;
  height: 14px;
  margin-left: 10px;
}
.center div:nth-child(3) {
  display: flex;
  justify-content: center;
  align-items: center;
}
.img img {
  width: 100%;
}
.img {
  background-color: #080810;
  overflow: hidden;
}
.img img:nth-child(1) {
  margin: 130px 0 89px;
}
.img img:nth-child(3) {
  margin: 144px 0 105px;
}
footer {
  background-color: #000;
  color: #fff;
  display: flex;
  justify-content: flex-start;
  padding-top: 130px;
  padding-bottom: 98px;
}
footer > img {
  margin: 0 84px 0 97px;
  width: 154px;
  height: 74px;
}
.right img:nth-child(2) {
  margin-left: 26px;
}
footer p {
  line-height: 36px;
  text-decoration-line: underline;
}
.right div:nth-child(2) {
  margin-top: 30px;
}
@media screen and (max-width: 600px) {
  footer > img {
    width: 60px;
    height: 24px;
    margin: 0 24px 0 48px;
  }
  footer {
    padding-top: 65px;
    padding-bottom: 50px;
  }
 
  .text div {
    font-size: 14px;
    line-height: 20px;
    width: 80%;
  }
  .text {
    padding: 50px 0;
  }
  .mining {
    line-height: 50px;
  }
  .center {
    font-size: 14px;
    width: 150px;
  }
  .center div:nth-child(2) {
    width: 1px;
    height: 20px;
  }
  .center img {
    width: 18px;
    height: 7px;
    margin-left: 10px;
  }
  .img img:nth-child(1) {
    margin: 65px 0 45px;
  }
  .img img:nth-child(3) {
    margin: 72px 0 52px;
  }
  .right img {
    width: 27px;
    height: 23px;
  }
  .right img:nth-child(2) {
    margin-left: 13px;
  }
  footer p {
    line-height: 24px;
    font-size: 12px;
    text-decoration-line: underline;
  }

  .right div:nth-child(2) {
    margin-top: 10px;
  }
  button {
    width: 101px;
    height: 32px;
    border-radius: 4px;
    position: absolute;
    bottom: 15px;
    right: 15%;
    font-size: 14px;
  }
}
